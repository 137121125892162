<template>
    <div class='CreateAndEdit'>
        <el-card class="box-card">
            <div>
                <i class="el-icon-close cha" @click="$router.go(-1)"></i>
                <div class="clearfix">
                    {{ roleId ? '编辑' : '新增' }}角色
                </div>
            </div>

            <el-divider></el-divider>
            <div class="formbox">
                <el-form :model="roleInfo" :rules="rules" ref="roleInfo">
                    <el-form-item label="角色编号" :label-width="formLabelWidth" prop="roleId">
                        <el-input v-model="roleInfo.roleId" autocomplete="off" :readonly="state"></el-input>
                    </el-form-item>
                    <el-form-item label="角色名称" :label-width="formLabelWidth" prop="roleName">
                        <el-input v-model="roleInfo.roleName" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="角色级别" :label-width="formLabelWidth" prop="level">
                        <!-- <el-input v-model="formInline.account" placeholder="请输入账号查询"></el-input> -->
                        <el-select placeholder="请选择角色级别" v-model="roleInfo.level">
                            <el-option v-for="grade in gradeList" :key="grade.pKey" :label="grade.pValue"
                                :value="grade.pKey"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="状态" :label-width="formLabelWidth" prop="userStatus">
                        <el-radio v-model="roleInfo.status" :label=0>停用</el-radio>
                        <el-radio v-model="roleInfo.status" :label=1>启用</el-radio>
                    </el-form-item>
                    <el-form-item label="开始时间" :label-width="formLabelWidth" prop="startDate">
                        <el-date-picker v-model="roleInfo.startDate" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd"
                            type="date" placeholder="选择日期">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="结束时间" :label-width="formLabelWidth" prop="endDate">
                        <el-date-picker v-model="roleInfo.endDate" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd"
                            type="date" placeholder="选择日期">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item class="df" :label-width="formLabelWidth">
                        <el-button class="btn" :disabled="submitFlag" @click="submitForm()">提交</el-button>
                        <el-button @click="handleReset">重置</el-button>
                    </el-form-item>
                </el-form>

            </div>

        </el-card>
    </div>
</template>

<script>
import { getGrades, queryRole, addRole, updateRole } from '@/api/role'
export default {
    props: {
        roleId: {
            type: [String, Number]
        }
    },
    data() {
        return {
            gradeList: [],
            roleInfo: {
                roleId: '',
                roleName: '',
                level: '0',
                status: 1,
                startDate: '',
                endDate: ''
            },
            formLabelWidth: "80px",
            rules: {
                roleId: [{ required: true, message: '请输入角色编号' }],
                roleName: [{ required: true, message: '请输入角色名称' }],
                level: [{ required: true, message: '请选择角色级别' }],
                status: [{ required: true }],
                startDate: [{ required: true, message: '请选择开始日期' }],
                endDate: [{ required: true, message: '请选择结束日期' }],
            },
            submitFlag: false,// 是否提交中,
            state: this.roleId ? true : false,//判断是新增还是修改
        }
    },
    mounted() {
        this.getGrade()
        this.roleId && this.loadRoleInfo()
    },
    methods: {
        //查询角色级别
        getGrade() {
            getGrades().then(res => {
                if (res.code === '000000') {
                    this.gradeList = res.data
                }
            })
        },
        async loadRoleInfo() {
            await queryRole(this.roleId).then(res => {
                if (res.code === '000000') {
                    this.roleInfo = res.data
                }
            })
        },
        submitForm() {
            this.$refs.roleInfo.validate((valid) => {
                if (valid) {
                    console.log("1111111111")
                    this.submitFlag = true
                    if (this.roleId) {
                        updateRole(this.roleInfo).then(res => {
                            if (res.code === '000000') {
                                this.$message.success('修改成功')
                                setTimeout(() => {
                                    this.$router.go(-1)
                                }, 1000)
                            }
                            this.submitFlag = false
                        })
                    } else {
                        addRole(this.roleInfo).then(res => {
                            if (res.code === '000000') {
                                this.$message.success('添加成功')
                                setTimeout(() => {
                                    this.$router.go(-1)
                                }, 1000)
                            }
                            this.submitFlag = false
                        })
                    }
                } else {
                    return false
                }
            })
        },
        handleReset() {
            this.$refs.roleInfo.resetFields()
        }
    }
}
</script>
<style lang="scss" scoped>
.CreateAndEdit {
    .el-card {
        position: relative;
        text-align: left;

        .cha {
            position: absolute;
            top: 5px;
            right: 5px;
            font-size: 30px;
            color: rgba(54, 55, 56, 0.445);
        }

        .cha:hover {
            cursor: pointer;
            color: #2a2d2f;
        }
    }

    .el-form-item {
        width: 500px;
    }

    .df {
        text-align: left;
    }

}
</style>